import React, { useEffect, useState } from 'react';
import { 
  Badge, Button, ButtonToolbar, Container, Modal, Row,
 } from 'reactstrap';
import { connect } from 'react-redux';
import { Tag } from 'antd';
import { 
  getUsers, updateUserPassword, updateUserStatus, deleteUser, getUsersById, 
} from '../../redux/actions/apis/user';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';
import EditForm from '../../global/editForm';
import ViewUser from './view';

const columns = [
  {
    Header: '#SL',
    accessor: 'id',
    disableGlobalFilter: true,
  },
  {
    Header: 'User Id',
    accessor: 'userId',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Mobile Number',
    accessor: 'mobileNumber',
  },
  {
    Header: 'Status',
    accessor: 'authenticated',
    disableGlobalFilter: true,
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: 'Action',
    accessor: 'action',
    disableGlobalFilter: true,
  },
];

const UserList = ({ 
  getUsers, updateUserPassword, updateUserStatus, deleteUser, getUsersById, 
 }) => {
  const [data, setData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [delModal, setDelModal] = useState(false); 
  const [id, setId] = useState(false); 

  const toggle = () => {
    setModal(prevState => !prevState);
  };
  const delToggle = () => {
    setDelModal(prevState => !prevState);
  };

  const vtoggle = () => {
    setViewModal(prevState => !prevState);
  };
  
  const getData = async () => {
    const res = await getUsers();
    setData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleStatus = async (val, id) => {
    const res = await updateUserStatus(val, id);
    if (res) {
      getData();
    }
  };

  const handleDelete = async (val, id) => {
    const res = await deleteUser(val, id);
    if (res) {
      getData();
    }
  };
  
  const createData = (id, userId, name, email, mobileNumber, authenticated, role, action) => {
    const a = 0;
    return { 
      id, userId, name, email, mobileNumber, authenticated, role, action,
    };
  };
  
  const rows = [...
    data?.length > 0
      ? data?.map((user, i) => createData(
        i + 1, 
        user?.user_id ? user?.user_id : '', 
        user?.name, 
        user?.email ? user?.email : '', 
        user?.mobile_number, 
        <>
          {user?.authenticated 
          ? (
            <> 
              <Tag color="#87d068">Active</Tag> &nbsp;
              <Tag size="sm" color="#f50" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(false, user?.user_id); }}>Disable</Tag> 
            </>
          ) 
          : (
            <>
              <Tag color="#f50">Disabled</Tag> &nbsp;
              <Tag size="sm" color="#87d068" style={{ cursor: 'pointer' }} onClick={() => { handleStatus(true, user?.user_id); }}>Activate</Tag> 
            </>
            )}
        
        </>,
        
        user?.roles?.name, 
        <>
          <Tag 
            color="#f50" 
            style={{ cursor: 'pointer' }} 
            onClick={() => history.push(`/user/edit?id=${user?.user_id}`)}
          >
            Edit
          </Tag> &nbsp;
          <Tag
            color="#108ee9" 
            style={{ cursor: 'pointer' }} 
            onClick={() => { vtoggle(user?.user_id); setId(user?.user_id); }}
          >
            View
          </Tag> &nbsp;
          <Tag color="#f50" style={{ cursor: 'pointer' }} onClick={() => { setId(user?.user_id); delToggle(); }}>Delete</Tag> &nbsp;
         
          <Tag color="#108ee9" style={{ cursor: 'pointer' }} onClick={() => { setId(user?.user_id); toggle(); }}>Change Password</Tag>
        </>,
        ))
      : '',
  ];
  const fields = [
    {
      type: 'password', 
      label: 'Password', 
      name: 'password', 
      placeholder: '8 Digit Password', 
      rules: { 
        required: 'This is required field', 
        pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
      },
    },
    {
      type: 'password', 
      label: 'Confirm Password', 
      name: 'confirm_password', 
      placeholder: 'Confirm Password', 
      rules: { 
        required: 'This is required field', 
        pattern: { value: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' },
      },
    },
];
  const delFields = [
    {
      type: 'input', 
      label: 'Remark', 
      name: 'remark', 
      rules: { 
        required: 'This is required field', 
        },
    },
];

  return (
    <Container>
      <Row>
        <PageHeader title="User management" subTitle="Manage all kind of user operations" />
        <Table columns={columns} rows={rows} title="User List" subTitle="All User" formTitle="Create a new user" samePage={false} route="/user/create" />
      </Row>
      
      {viewModal ? <ViewUser id={id} toggle={vtoggle} open={viewModal} /> : null}

      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Change Password</h4>
        </div>
        <div className="modal__body">
          <EditForm isAboveError fiels={fields} submitFnc={updateUserPassword} id={id} closeFnc={toggle} />
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
      
      <Modal
        isOpen={delModal}
        toggle={delToggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={delToggle}
          />
          <span className="lnr lnr-flag modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">Delete User</h4>
        </div>
        <div className="modal__body">
          <EditForm isAboveError fiels={delFields} submitFnc={handleDelete} id={id} closeFnc={delToggle} />
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={delToggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={delToggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    </Container>
  );
};

export default connect(null, { 
  getUsers, updateUserPassword, updateUserStatus, deleteUser, getUsersById, 
 })(UserList);
