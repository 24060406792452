import axios from 'axios';

export default (token = false) => (
    axios.create({
        // baseURL: 'http://192.168.1.129:3000/admin/v1/',
        baseURL: 'https://sbss-api.redorch.com/sailor/v1/',
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    })
    );
