import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({
  onClick, changeToLight, changeToDark, sidebarCollapse,
}) => (
  <div className="sidebar__content">
    <ul className="sidebar__block">
      <SidebarLink
        title="Dashboard"
        icon="chart-bars"
        route="/home"
        onClick={onClick}
      />
      {JSON.parse(localStorage.getItem('access'))?.user_management
      ? (
        <SidebarCategory title="User Management" icon="user" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="User List" route="/user/list" onClick={onClick} />
          <SidebarLink title="Role List" route="/user/role" onClick={onClick} />
        </SidebarCategory>
        )
      : null}

      {JSON.parse(localStorage.getItem('access'))?.agent_management
      ? (
        <SidebarCategory title="Agent Management" icon="users" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Agent List" route="/agent/list" onClick={onClick} />
          <SidebarLink title="Create Agent" route="/agent/create" onClick={onClick} />
        </SidebarCategory>
        )
      : null}

      {JSON.parse(localStorage.getItem('access'))?.union_management
      ? (
        <SidebarCategory title="Union Management" icon="store" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Union List" route="/union/list" onClick={onClick} />
          <SidebarLink title="Create Union" route="/union/create" onClick={onClick} />
        </SidebarCategory>
        )
      : null}

      {JSON.parse(localStorage.getItem('access'))?.candidate_management
      ? (
        <SidebarCategory title="Candidate Management" icon="users" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Candidate List" route="/candidate/list" onClick={onClick} />
          {/* <SidebarLink title="Candidate Print" route="/candidate/print" onClick={onClick} /> */}
        </SidebarCategory>
        )
      : null}

      {JSON.parse(localStorage.getItem('access'))?.setting_management
      ? (
        <SidebarCategory title="Setting Management" icon="cog" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="API List" route="/setting/api" onClick={onClick} />
        </SidebarCategory>
        )
      : null}
      
    </ul>
  </div>
);

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
  onClick: () => {},
};

export default SidebarContent;
