import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../../../Layout/index';
import OnLineMarketingDashboard from '../../../Dashboards/OnLineMarketing/index';
import history from '../../../../history';
import User from './User';
import Agent from './Agent';
import Union from './Union';
import Candidate from './candidate';
import setting from './setting';
import FinanceDashboard from '../../../Dashboards/Finance';

export default () => {
  useEffect(() => {
    if (!localStorage.getItem('tk_auth')) {
      history.push('/login');
    }
  }, []);
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <switch>
          <Route path="/user" component={User} />
          <Route path="/agent" component={Agent} />
          <Route path="/union" component={Union} />
          <Route path="/candidate/*" component={Candidate} />
          <Route path="/setting" component={setting} />
          <Route exact path="/dash" component={FinanceDashboard} />
          <Route exact path="/home" component={OnLineMarketingDashboard} />
          {/* <Route exact path="/" component={OnLineMarketingDashboard} /> */}
        </switch>
      </div>
    </div>
  );
};
