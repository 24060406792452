import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Form from '../../global/form';
import { getAssignableRoles, createRole } from '../../redux/actions/apis/role';

const CreateRole = ({ getAssignableRoles, createRole }) => {
    const [roles, setRoles] = useState([]);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    let role = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    const access = [
      { value: 'user_management', label: 'User management' },
      { value: 'agent_management', label: 'Agent management' },
      { value: 'operation_management', label: 'Operation management' },
      { value: 'candidate_management', label: 'Candidate management' },
      { value: 'union_management', label: 'Union management' },
      { value: 'setting_management', label: 'Setting management' },
    ];
    useEffect(async () => {
      const res = await getAssignableRoles();
      setRoles(res);
    }, []);
    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };
    const closeModal = () => {
      setError('');
      setIsOpen(false);
    };

    const openModal = () => {
      setIsOpen(true);
      setError('');
    };

    role = (
      roles ? roles?.map(item => createOption(item.role_id, item.name))
    : null);
    
 

    const fields = [
        
        {
          type: 'input', 
          label: 'Role Name', 
          name: 'name', 
          placeholder: 'Name', 
          rules: { 
            required: 'This is required field', 
            },
        },
        { 
          type: 'dropdown', 
          label: 'Access', 
          name: 'access', 
          placeholder: 'Access', 
          rules: { required: 'This is required field' },
          option: access,
          multi: true,
        },
        { 
          type: 'dropdown', 
          label: 'Status', 
          name: 'authenticated', 
          placeholder: 'Status', 
          rules: { required: 'This is required field' },
          option: authenticated,
          multi: false,
        },
       
    ];
    
    
    return ( 
      // <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen}>
      //   <ModalHeader>Form</ModalHeader>
      //   <ModalBody>
      //     <p>
      //       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint neque aut, debitis, 
      //       similique temporibus amet voluptatem minima sequi
      //       tempore reiciendis laborum itaque numquam omnis mollitia, quibusdam magnam optio dolorem corrupti!
      //     </p>
      <Form isHorizontal isAboveError title="Create a new role" fiels={fields} submitFnc={createRole} />
      //   </ModalBody>
      // </Modal>
     );
};
 
export default connect(null, { getAssignableRoles, createRole })(CreateRole);
