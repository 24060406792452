import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CandidateList from '../../../../pages/candidate';
// import CandidatePrint from '../../../../pages/candidate/candidatePrint';

export default () => (
  <Switch>
    <Route exact path="/candidate/list" component={CandidateList} />
    {/* <Route exact path="/candidate/print" component={CandidatePrint} /> */}
    {/* <Route exact path="/union/create" component={CreateUnion} />
    <Route exact path="/union/edit" component={EditUnion} /> */}
  </Switch>
);
