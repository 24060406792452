import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AgentList from '../../../../pages/agent';
import CreateAgent from '../../../../pages/agent/createAgent';
import EditAgent from '../../../../pages/agent/editAgent';

export default () => (
  <Switch>
    <Route exact path="/agent/list" component={AgentList} />
    <Route exact path="/agent/create" component={CreateAgent} />
    <Route exact path="/agent/edit" component={EditAgent} />
  </Switch>
);
