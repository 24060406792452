import React, { useState } from 'react';
import { Container, Modal, Table } from 'reactstrap';
import { Col, Row } from 'antd';
import './style.css';

export const CandidatePrint = React.forwardRef((props, ref) => {
    const [first, setfirst] = useState('second');
    
    const capitalizer = s => s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');

    return (
      
      <Modal
        isOpen={props?.open}
        toggle={props?.toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
        size="lg" 
        style={{ maxWidth: '90%', width: '100%' }}
      >
        <div
          ref={ref} 
          style={{ 
            backgroundColor: '#FFFF', color: '#000', fontSize: '12px', padding: '15px',
          }}
        >
          {props?.component}
          <br />
          <div className="wrapper">
            <h6 className="title" style={{ color: 'black' }}>Strengthening Women’s Ability for Productive New Opportunities</h6>
            <h6 className="title" style={{ color: 'black' }}>Local Government Division, MoLGRD&C</h6>
            <p className="colored_title" style={{ color: '#FFF' }}>Interview Format for Beneficiaries Selection</p>
          </div>
          <br />
          <Row gutter={16} style={{ textAlign: 'left' }}>
            <Col span={5} style={{ fontWeight: 'bold' }}>Address</Col>
            <Col span={6} style={{ fontWeight: 'bold' }}>Name of Interviewer:</Col>
            <Col span={5} style={{ fontWeight: 'bold' }}>Signature</Col>
            <Col span={8} style={{ fontWeight: 'bold' }}>Page: From ________To________</Col>
            <Col span={24}>&nbsp;</Col>
            <Col span={24}>&nbsp;</Col>
            <Col span={5}>Union: {props?.union ? props?.union : 'All'}</Col>
            
            <Col span={6}>1:</Col>
            <Col span={5}>______________________</Col>
            <Col span={8} style={{ fontWeight: 'bold' }}>Date:</Col>
            <Col span={24}>&nbsp;</Col>
            <Col span={24}>&nbsp;</Col>

            <Col span={5}>Upazilla: {props?.upazilla ? props?.upazilla : 'All'}</Col>
            <Col span={6}>2:</Col>
            <Col span={5}>______________________</Col>
            <Col span={8}>&nbsp;</Col>
            <Col span={24}>&nbsp;</Col>
            <Col span={24}>&nbsp;</Col>

            <Col span={5}>District: {props?.district ? props?.district : 'All'}</Col>
            <Col span={6}>3:</Col>
            <Col span={5}>______________________</Col>
            <Col span={8}>&nbsp;</Col>
          </Row>
          <br />
          {/* <Row gutter={0}>
            <Col span={1} className="header_item">SL</Col>
            <Col span={2} className="header_item">Candidate Name</Col>
            <Col span={2} className="header_item">Father/Husband name</Col>
            <Col span={1} className="header_item">Age</Col>
            <Col span={1} className="header_item">Village</Col>
            <Col span={1} className="header_item">Ward no</Col>
            <Col span={1} className="header_item">Cell no</Col>
            <Col span={1} className="header_item">Marital Status</Col>
            <Col span={5} className="header_item">No of Dependents</Col>
            <Col span={1} className="header_item">Minimum HH Income</Col>
            <Col span={1} className="header_item">Health Fitness</Col>
            <Col span={1} className="header_item">Education</Col>
            <Col span={1} className="header_item">Willingness to work in Garments</Col>
            <Col span={1} className="header_item">Willingness to Work for Public works</Col>
            <Col span={1} className="header_item">Receive any other Govt./Private Project Aid</Col>
            <Col span={1} className="header_item">if Yes,Which one</Col>

          </Row> */}
          <table size="sm">
            <thead>
              <tr>
                <th className="th">SL</th>
                <th className="th">Candidate ID</th>
                <th className="th">Candidate Name</th>
                <th className="th">Age</th>
                <th className="th">Group</th>
                <th className="th">Ward no</th>
                <th className="th">Cell no</th>
                <th className="th">Marital Status</th>
                <th className="th">Minimum HH Income</th>
                <th className="th">Diseases in Last One Year</th>
                <th className="th">Education</th>
                <th className="th">Willingness to work in Garments</th>
                <th className="th">Willingness to Work for Public works</th>
                <th className="th">Receive any other Govt./Private Project Aid</th>
                <th className="th">if Yes,Which one</th>
                <th className="th">State</th>
                <th className="th">Potential Candidate (&#10004;/&#10006;)</th>
                <th className="th">Selected from Lottery (&#10004;/&#10006;)</th>
              </tr>
            </thead>
            <tbody>
              {props?.data?.map((item, id) => (
                <tr>
                  <th className="th" scope="row">{id + 1}</th>
                  <td className="td">{item['candidate id']}</td>
                  <td className="td">{item?.name}</td>
                  <td className="td">{item?.age}</td>
                  <td className="td">{item?.group}</td>
                  <td className="td">{item['word no']}</td>
                  <td className="td">{item['mobile number']}</td>
                  <td className="td">{item['marital status']}</td>
                  <td className="td">{item['minimum income']}</td>
                  <td className="td">{capitalizer(item['diseases in last one year'])}</td>
                  <td className="td">{item['education level']}</td>
                  <td className="td">{capitalizer(item['work in dhaka'])}</td>
                  <td className="td">{capitalizer(item['public work'])}</td>
                  <td className="td">{capitalizer(item['allowance from P&P org'])}</td>
                  <td className="td">{item['allowance name']}</td>
                  <td className="td">{item.status}</td>
                  <td className="td">{item['---']}</td>
                  <td className="td">{item['---']}</td>
                  {/* <td className="td">{item?.selection_criteria ? item?.selection_criteria['education status'] : ''}</td>
                  <td className="td">{item?.selection_criteria ? item?.selection_criteria['public work'] : ''}</td>
                  <td className="td">{item?.selection_criteria ? item?.selection_criteria['work in Dhaka'] : ''}</td>
                  <td className="td">{item?.selection_criteria ? item?.selection_criteria['work in Dhaka'] : ''}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
          {/* <p style={{ color: 'black', textAlign: 'left' }}>
            Marital Staus: Married with disabled husband, Married with able husband, Unmarried, Divircee, Widow, 
            Transgender (should mention theird husband else father&apos;s name)
          </p> */}
          <br />
          <br />
          <Row gutter={16} style={{ textAlign: 'left' }}>
            <Col span={18}>Copy to: UP File</Col> 
            <Col span={6}>Approved by: _____________________</Col> 
            <Col span={18}>Representaitve from UNDP</Col> 
            <Col span={6} style={{ fontSize: '10px' }}>(UP Chairman)</Col> 
            <Col span={18}>&nbsp;</Col>
            <Col span={24}>&nbsp;</Col>
            <Col span={24}>&nbsp;</Col>
            <Col span={18}>&nbsp;</Col>
            <Col span={6}>Date: _____________________</Col> 
            <Col span={18}>&nbsp;</Col>
            <Col span={6} style={{ fontSize: '10px' }}>(UP Official Seal)</Col>
            <Col span={18}>&nbsp;</Col>
            <Col span={6}>&nbsp;</Col>
          </Row>
        </div>
      </Modal>
    
    );
});

