import React, { useEffect, useState } from 'react';
import { 
  Button, ButtonToolbar, Container, Modal,
  Form, FormGroup, Label, Input, 
 } from 'reactstrap';
 import { 
  Col, Row, Statistic, Badge, Descriptions, Timeline, Divider, List, Typography,
 } from 'antd';
import { connect } from 'react-redux';
import { 
  getUsersById, 
} from '../../redux/actions/apis/user';
import PageHeader from '../../global/pageHeader';
import Table from '../../global/table';
import history from '../../history';


const ViewUser = ({ 
  getUsersById, toggle, open, id,
 }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getUsersById(id);
    setData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  
  return (
    <Container>
      <Modal
        isOpen={open}
        toggle={toggle}
        modalClassName="modal ltr-support fade"
        className="modal-dialog--danger"
        size="lg" 
        style={{ maxWidth: '90%', width: '100%' }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <span className="lnr lnr-user modal__title-ico">  </span>
          <h4 className="text-modal  modal__title">User Details</h4>
        </div>
        <div className="modal__body">
          <Row gutter={16}>
            <Col span={24}>
              <Descriptions title="User Info" size="small">
                <Descriptions.Item label="User Id">{data?.user_id}</Descriptions.Item>
                <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
                <Descriptions.Item label="Mobile number">{data?.mobile_number}</Descriptions.Item>
                <Descriptions.Item label="Address">{data?.address}</Descriptions.Item>
                <Descriptions.Item label="Email">{data?.email}</Descriptions.Item>
                <Descriptions.Item label="Role">{data?.roles?.name}</Descriptions.Item>
                <Descriptions.Item label="Active">{data?.authenticated
                  ? <Badge status="success" text="Active" /> : <Badge status="error" text="Inactive" />}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            
            <Col span={24}>
              <h4>Logs</h4>
              <br />
              <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
                {data?.logs?.map(item => (
                  <Timeline.Item>
                    <p>{item?.message}</p>
                    <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{item?.created_at}</p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" color="danger" onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    </Container>
  );
};

export default connect(null, { 
  getUsersById,
 })(ViewUser);
