import axios from 'axios';
import history from '../../../history';
import api from './base';
import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_AUTH_ERROR,
    VALIDATION_ERROR,
} from '../../types';


export const getCandidate = (p, user, nid, s, n, w, g, u, uc, dis, up, d) => async (dispatch) => {
  console.log(u);
  dispatch({ type: LOADING });
  try {
    if (d === '') {
      const response = await api(localStorage.getItem('tk_auth'))
      .get(`/candidate-management/candidate/list?p=${p}&user=${user}&nid=${nid}&s=${s}&n=${n}&w=${w}&g=${g}&u=${u.split('-')[0] ? u.split('-')[0] : ''}&uc=${uc}&dis=${dis}&up=${up}&d=${d}`);
        if (response.status === 200) {
            dispatch({ type: LOADED });
            return response?.data;
        }
    } if (d === 'download') {
      const response = await axios(`
      https://sbss-api.redorch.com/sailor/v1/candidate-management/candidate/list?p=${p}&user=${user}&nid=${nid}&s=${s}&n=${n}&w=${w}&g=${g}&u=${u.split('-')[0]}&uc=${uc}&dis=${dis}&up=${up}&d=${d}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('tk_auth')}`,
          },
          method: 'GET',
          responseType: 'blob',
        });
      
      if (response.status === 200) {
        dispatch({ type: LOADED });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'candidate.xlsx',
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } else {
      const response = await api(localStorage.getItem('tk_auth'))
      .get(`/candidate-management/candidate/list?p=${p}&user=${user}&nid=${nid}&s=${s}&n=${n}&w=${w}&g=${g}&u=${u.split('-')[0] ? u.split('-')[0] : ''}&uc=${uc}&dis=${dis}&up=${up}&d=${d}`);
        if (response.status === 200) {
            dispatch({ type: LOADED });
            return response?.data;
      }
    }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};

export const getCandidateByID = id => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
      const response = await api(localStorage.getItem('tk_auth')).get(`candidate-management/candidate/detail?candidate=${id}`);
          if (response.status === 200) {
              dispatch({ type: LOADED });
              return response?.data;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};

export const getCandidateStac = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
      const response = await api(localStorage.getItem('tk_auth')).get('/candidate-management/candidate/statistic');
          if (response.status === 200) {
              dispatch({ type: LOADED });
              return response.data;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};
