import history from '../../../history';
import api from './base';
import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_AUTH_ERROR,
    VALIDATION_ERROR,
} from '../../types';

export const getUsers = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
        const response = await api(localStorage.getItem('tk_auth')).get('/user-management/user/all');
            if (response.status === 200) {
                dispatch({ type: LOADED });
                return response?.data?.users;
            }
    } catch (err) {
        /* =============Start========== */
          if (err?.response?.status === 422) {
            err?.response?.data?.errors?.forEach((item) => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
              localStorage.clear();
              history.push('/login');
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
          dispatch({ type: LOADED });
        /* ===========End============ */
      }
      return true;
};

export const createUser = data => async (dispatch) => {
    dispatch({ type: LOADING });
    const object = {
      user_id: data.user_id,
      name: data.name,
      email: data.email,
      mobile_number: data.mobile_number,
      password: data.password,
      address: data.address,
      authenticated: true,
      role: data.role?.value,
    };
    try {
        const response = await api(localStorage.getItem('tk_auth')).post('/user-management/user/create', { ...object });
            if (response.status === 201) {
                dispatch({ type: LOADED });
                dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
                return response.status;
            }
    } catch (err) {
        /* =============Start========== */
          if (err?.response?.status === 422) {
            err?.response?.data?.errors?.forEach((item) => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
              localStorage.clear();
              history.push('/login');
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
          dispatch({ type: LOADED });
        /* ===========End============ */
      }
      return true;
};

export const getUsersById = id => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem('tk_auth')).get(
      `/user-management/user/detail?user=${id}`,
    );
    dispatch({ type: LOADED });
    return response.data.user;
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateUser = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
      user: id,
      // user_id: data.user_id,
      name: data.name,
      email: data.email,
      mobile_number: data.mobile_number,
      authenticated: true,
      role: data.role?.value,
      address: data?.address,
      remark: data?.remark,
    };

  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/user-management/user/update', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: 'User is updated succesfully' });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateUserPassword = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
      user: id,
      password: data.password,
      confirm_password: data.confirm_password,
    };

  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/user-management/user/password/update', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response?.data?.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateUserStatus = (authenticated, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
      user: id,
      authenticated,
    };

  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/user-management/user/authenticate', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response?.data?.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const deleteUser = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
      user: id,
      remark: data?.remark,
    };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/user-management/user/delete', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response?.data?.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};
