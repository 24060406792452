import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UnionList from '../../../../pages/union';
import CreateUnion from '../../../../pages/union/createUnion';
import EditUnion from '../../../../pages/union/editUnion';

export default () => (
  <Switch>
    <Route exact path="/union/list" component={UnionList} />
    <Route exact path="/union/create" component={CreateUnion} />
    <Route exact path="/union/edit" component={EditUnion} />
  </Switch>
);
