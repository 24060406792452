import history from '../../../history';
import api from './base';
import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_AUTH_ERROR,
    VALIDATION_ERROR,
} from '../../types';

export const login = data => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
        const response = await api(localStorage.getItem('token')).post('access/signin', { ...data },
        { headers: { 'content-type': 'application/json' } });
            if (response.status === 201) {
                localStorage.setItem('tk_auth', response.data.data.token);
                localStorage.setItem('name', response.data.data.name);
                // localStorage.setItem('email', email);
                localStorage.setItem('role', response.data.data.roles.name);
                localStorage.setItem('access', JSON.stringify(response.data.data.roles.access));
                dispatch({ type: LOADED });
                history.push('/home');
            }
    } catch (err) {
        /* =============Start========== */
          if (err?.response?.status === 422) {
            err?.response?.data?.errors?.forEach((item) => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
              localStorage.clear();
              history.push('/login');
          }
          dispatch({ type: AUTH_ERROR, payload: err.response ? err.response.data.message : history.push('/err') });
          dispatch({ type: LOADED });
        /* ===========End============ */
      }
};

export const logOut = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
        const response = await api(localStorage.getItem('tk_auth')).patch('/access/signout');
        if (response.status === 201) {
            localStorage.clear();
            history.push('/logIn');
        }
        dispatch({ type: AUTH_SUCCESS, payload: 'Successfully logout' });
        dispatch({ type: LOADED });
    } catch (err) {
        /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors?.forEach((item) => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
              localStorage.clear();
              history.push('/login');
          }
          dispatch({ type: AUTH_ERROR, payload: err.response ? err.response.data.message : history.push('/err') });
          dispatch({ type: LOADED });
        /* ===========End============ */
      }
  };
