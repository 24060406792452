import history from '../../../history';
import api from './base';
import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_AUTH_ERROR,
    VALIDATION_ERROR,
} from '../../types';

export const getAllRoles = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
        const response = await api(localStorage.getItem('tk_auth')).get('/user-management/role/all');
            if (response.status === 200) {
                dispatch({ type: LOADED });
                return response.data.roles;
            }
    } catch (err) {
        /* =============Start========== */
          if (err?.response?.status === 422) {
            err?.response?.data?.errors?.forEach((item) => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
              localStorage.clear();
              history.push('/login');
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
          dispatch({ type: LOADED });
        /* ===========End============ */
      }
      return true;
};

export const getAssignableRoles = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
        const response = await api(localStorage.getItem('tk_auth')).get('/user-management/role/assignable');
            if (response.status === 200) {
                dispatch({ type: LOADED });
                return response.data.roles;
            }
    } catch (err) {
        /* =============Start========== */
          if (err?.response?.status === 422) {
            err?.response?.data?.errors?.forEach((item) => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
              localStorage.clear();
              history.push('/login');
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
          dispatch({ type: LOADED });
        /* ===========End============ */
      }
      return true;
};

export const createRole = data => async (dispatch) => {
    dispatch({ type: LOADING });
    const access = [{
      user_management: false,
      agent_management: false,
      operation_management: false,
      candidate_management: false,
      union_management: false,
      setting_management: false,
    }];
    data?.access?.forEach((item) => { 
        access[0][item?.value] = true;
        // if (item?.value === )
     });
    const object = {
      name: data.name,
      active: data?.authenticated?.value,
      access: access[0],
    };
    try {
        const response = await api(localStorage.getItem('tk_auth')).post('/user-management/role/create', { ...object });
            if (response.status === 201) {
                dispatch({ type: LOADED });
                dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
                return response.status;
            }
    } catch (err) {
        /* =============Start========== */
          if (err?.response?.status === 422) {
            err?.response?.data?.errors?.forEach((item) => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
              localStorage.clear();
              history.push('/login');
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
          dispatch({ type: LOADED });
        /* ===========End============ */
      }
      return true;
};

export const deleteRole = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
      role: id,
      remark: data?.remark,
    };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/user-management/role/delete', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response?.data?.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateURoleStatus = (active, id) => async (dispatch) => {
  dispatch({ type: LOADING });
    const object = {
      role: id,
      active,
    };

  dispatch({ type: LOADED });
  try {
    const response = await api(localStorage.getItem('tk_auth')).patch('/user-management/role/active', { ...object });

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response?.data?.message });
      return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const getRoleById = id => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem('tk_auth')).get(
      `/user-management/role/detail?role=${id}`,
    );
    dispatch({ type: LOADED });
    return response.data.role;
  } catch (err) {
    /* =============Start========== */
      if (err?.response?.status === 422) {
        err?.response?.data?.errors?.forEach((item) => {
          dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
      });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push('/login');
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
      dispatch({ type: LOADED });
    }
    return true;
};

export const updateRole = (data, id) => async (dispatch) => {
  dispatch({ type: LOADING });
  const access = [{
    user_management: false,
    agent_management: false,
    operation_management: false,
    candidate_management: false,
    union_management: false,
    setting_management: false,
  }];
  data?.access?.forEach((item) => { 
      access[0][item?.value] = true;
      // if (item?.value === )
   });
  const object = {
    role: id,
    name: data.name,
    active: data?.active?.value,
    access: access[0],
    remark: data?.remark,
  };
  try {
      const response = await api(localStorage.getItem('tk_auth')).patch('/user-management/role/update', { ...object });
          if (response.status === 201) {
              dispatch({ type: LOADED });
              dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
              history.push('/user/role');
              return response.status;
          }
  } catch (err) {
      /* =============Start========== */
        if (err?.response?.status === 422) {
          err?.response?.data?.errors?.forEach((item) => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{ message: item.message, path: item.path[0] }] });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push('/login');
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/err') });
        dispatch({ type: LOADED });
      /* ===========End============ */
    }
    return true;
};
