import React, { useState } from 'react';
import { 
    Button,
  Card, CardBody, Col, Row,
 } from 'reactstrap';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import ReactTableCustomizer from '@/shared/components/table/components/ReactTableCustomizer';
import PlusIcon from 'mdi-react/PlusIcon';
import { Pagination, Tag } from 'antd';
// import { Drawer } from '@material-ui/core';
import history from '../history';
import FloatForm from './floatForm';

const Table = ({ 
    columns, rows, title, subTitle, formTitle, formSubTitle, samePage, route,
    count, handlePageSearch, isResizable,
 }) => {
  const [isSortable, setIsSortable] = useState(false);
  // const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(false);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  // const handleClickIsResizable = () => {
  //   setIsResizable(!isResizable);
  // };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [20],
    placeholder: 'Search ...',
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlePageChange = (e) => {
    handlePageSearch(e);
    // console.log(e);
  };
    return ( 
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="react-table__wrapper">
              <div className="card__title">
                <h5 className="bold-text">{title}</h5>
                <h5 className="subhead">{subTitle}</h5>
              </div>
              {/* <ReactTableCustomizer
                handleClickIsSortable={handleClickIsSortable}
                handleClickWithPagination={handleClickWithPagination}
                handleClickWithSearchEngine={handleClickWithSearchEngine}
                handleClickIsResizable={handleClickIsResizable}
                isResizable={isResizable}
                isSortable={isSortable}
                withPagination={withPagination}
                withSearchEngine={withSearchEngine}
              /> */}
              {/* <Drawer anchor="right" open={open}> */}
              {open
              ? <FloatForm isHorizontal closeFnc={handleDrawerClose} title={formTitle} subtitle={formSubTitle} />
              : null}
              
              {count ? <Tag color="#f50">{count} data found</Tag> : null}
              {
              samePage === true
              ? (
                <Button variant="contained" color="danger" size="sm" onClick={handleDrawerOpen}>
                  <PlusIcon style={{ 
                      margin: '5px', fontSize: '16px', fontWeight: 'bold', color: '#FFF',
                      }} 
                  />Create
                </Button>
              )
              : null
              }
              {
              samePage === false
              ? (
                <Button variant="contained" color="danger" size="sm" onClick={() => history.push(route)}>
                  <PlusIcon style={{ 
                      margin: '5px', fontSize: '16px', fontWeight: 'bold', color: '#FFF',
                      }} 
                  />Create
                </Button>
              )
              : null
              }
              {/* </Drawer> */}
              
              {/* <IconButton component="span" color="primary" size="small"><PlusIcon />Create</IconButton> */}
            </div>
            <ReactTableBase
              key={withSearchEngine ? 'searchable' : 'common'}
              columns={columns}
              data={rows}
              tableConfig={tableConfig}
            />
            <Pagination defaultCurrent={1} pageSize={20} total={count} onChange={(e) => { handlePageChange(e); }} />
          </CardBody>
        </Card>
      </Col>
     );
};
 
export default Table;
