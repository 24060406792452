import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import MicrosoftOfficeIcon from 'mdi-react/MicrosoftOfficeIcon';
import EditForm from '../../global/editForm';
import { getAssignableRoles, getRoleById, updateRole } from '../../redux/actions/apis/role';

const EditRole = ({ 
      getAssignableRoles, updateRole, getRoleById, location,
    }) => {
    const [roles, setRoles] = useState([]);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [data, setData] = useState(null);
    const [ID, setID] = useState(null);
    const [finalOption, setFinalOption] = useState([]);
    let role = [];
    const authenticated = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' },
    ];
    
    const acc = [
      { value: 'user_management', label: 'User management' },
      { value: 'agent_management', label: 'Agent management' },
      { value: 'operation_management', label: 'Operation management' },
      { value: 'candidate_management', label: 'Candidate management' },
      { value: 'union_management', label: 'Union management' },
      { value: 'setting_management', label: 'Setting management' },
    ];

    useEffect(async () => {
      const res = await getAssignableRoles();
      setRoles(res);
      const search = location?.search;
      const id = new URLSearchParams(search).get('id');
      setID(id);
      const data = await getRoleById(id);
      setData(data);
      const p = [];
      for (let i = 0; i < acc.length; i += 1) {
        if (data?.access?.[acc[i]?.value] === true) {
          p.push(acc[i]);
        }
      }
      setFinalOption(p);
    }, []);

    const createOption = (value, label) => {
      const a = '';
      return { value, label };
    };

    const closeModal = () => {
      setError('');
      setIsOpen(false);
    };

    const openModal = () => {
      setIsOpen(true);
      setError('');
    };

    role = (
      roles ? roles.map(item => createOption(item.role_id, item.name))
    : null);
    // data?.access?.forEach((item) => { 
    //   console.log(item);
    //   // acc[0][item?.value] = true;
    //   return 0;
    // });
   
    const test = finalOption?.map(item => [{
      value: item?.value,
      label: item?.label,
    }]);
    // console.log(finalOption?.length);
    // console.log(test);

    const fields = [
       
        {
          type: 'input', 
          label: 'Role Name', 
          name: 'name', 
          placeholder: 'Name', 
          value: data?.name,
          rules: { 
            required: 'This is required field', 
            },
        },
        { 
          type: 'dropdown', 
          label: 'Access', 
          name: 'access', 
          placeholder: 'access', 
          value: 
          finalOption,
          rules: { required: 'This is required field' },
          option: acc,
          multi: true,
        },
        
        { 
          type: 'dropdown', 
          label: 'Status', 
          name: 'active', 
          placeholder: 'Status', 
          value: {
            value: data?.active,
            label: data?.active ? 'Active' : 'Inactive',
          },
          rules: { required: 'This is required field' },
          option: authenticated,
          multi: false,
        },
        
        {
          type: 'input', 
          label: 'Remark', 
          name: 'remark', 
          placeholder: 'Remark', 
          value: data?.remark,
          rules: { required: 'This is required field' },
        },
    ];
    
    
    return ( 
      // <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" size="sm" isOpen={isOpen}>
      //   <ModalHeader>Form</ModalHeader>
      //   <ModalBody>
      //     <p>
      //       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint neque aut, debitis, 
      //       similique temporibus amet voluptatem minima sequi
      //       tempore reiciendis laborum itaque numquam omnis mollitia, quibusdam magnam optio dolorem corrupti!
      //     </p>
      finalOption?.length > 0
      ? <EditForm isHorizontal isAboveError title="Edit Role" fiels={fields} submitFnc={updateRole} id={ID} />
      : null      
      //   </ModalBody>
      // </Modal>
     );
};
 
export default connect(null, { 
  getAssignableRoles, updateRole, getRoleById,
 })(EditRole);
